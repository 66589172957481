body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.demo_game__1Oljw .demo_action__aiRnw {
  padding: 0 1em; }
  .demo_game__1Oljw .demo_action__aiRnw button {
    margin-left: 1em; }

.demo_game__1Oljw .demo_row__3KCZZ .demo_block__NUbPN {
  width: 48px;
  height: 48px;
  margin: 2px;
  border: 2px solid gray;
  line-height: 48px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  opacity: 0.6; }
  .demo_game__1Oljw .demo_row__3KCZZ .demo_block__NUbPN:hover {
    opacity: 1; }
  .demo_game__1Oljw .demo_row__3KCZZ .demo_block__NUbPN.demo_A__2yh9I {
    background-color: #6ffb89; }
  .demo_game__1Oljw .demo_row__3KCZZ .demo_block__NUbPN.demo_B__2hIUS {
    background-color: #fb817d; }
  .demo_game__1Oljw .demo_row__3KCZZ .demo_block__NUbPN.demo_barrier__1v7Gl {
    background-color: #61dafb; }
  .demo_game__1Oljw .demo_row__3KCZZ .demo_block__NUbPN.demo_open__Eelo2 {
    border-color: #6ffb89; }
  .demo_game__1Oljw .demo_row__3KCZZ .demo_block__NUbPN.demo_close__1LMmZ {
    border-color: #3a6ffb; }
  .demo_game__1Oljw .demo_row__3KCZZ .demo_block__NUbPN.demo_path__256-w {
    font-weight: bold;
    color: red; }

